import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Image from "@components/common/CloudinaryImage";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";
// import { InlineWidget } from "react-calendly";
// import { FaParking } from "react-icons/fa";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    Arriba un <strong>curs específic</strong> per preparar l'accés a la Guàrdia
                    Urbana de Reus 2024.
                </p>

                <p>
                    Els cursos específics de <strong>iOpos</strong> són molts complerts, ja que ens
                    basem en els patrons d'anys anteriors.
                </p>

                <p>
                    Són <strong>cursos fàcils d'estudiar</strong>, on tu decideixes com i quan
                    avançar.
                </p>

                <p>
                    Al curs trobaràs tot el contingut necessari per posicionar-te entre els millors.
                </p>

                <p>Un curs viu i en constant evolució.</p>

                <p>
                    <strong>Dins del curs trobaràs:</strong>
                </p>

                <ul className="">
                    <p>- Cultura general.</p>
                    <p>
                        - Els <strong>40 temes exigits</strong> de les bases de 2024.
                    </p>
                    <p>- Consistori.</p>
                    <p>- Notícies d'actualitat.</p>
                    <p>- Premis.</p>
                    <p>- El Municipi.</p>
                    <p>- Dades rellevants.</p>
                    <p>- 7 exàmens oficials.</p>
                    <p>- I Simulacres on et posarem a prova!!</p>
                </ul>

                <p>Més de 40 temes del temari de les bases actualitzat</p>

                <p>
                    <strong>Pagament únic de només 79,99 euros.</strong>
                </p>

                <p>
                    Amb promo de llançament a les 20 primeres matrícules per només{" "}
                    <strong>69,99 euros</strong>.
                </p>

                <p>
                    <strong>El curs estarà obert fins al dia de l'examen.</strong>
                </p>

                <p>
                    <strong>Entra ara i avança al teu ritme!</strong>
                </p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Prepara't per a la Guàrdia Urbana de Reus 2024 amb un curs complet i actualitzat."
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
